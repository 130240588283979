// BOME CHAIN
// 9736 MAINNET
// 9738 TESTNET
export const CHAINS = {
    9736: {
        chainId: 9736,
        name: "Bome Mainnet",
        currency: "BOME",
        network: "Bome Mainnet",
        nativeCurrency: {
            decimals: 18,
            name: "BOME",
            symbol: "BOME",
        },
        rpcUrl: "https://rpc.bomechain.org",
        explorerUrl: "https://bomescan.org",
    },
    9738: {
        chainId: 9738,
        name: "Bome Testnet",
        currency: "BOME",
        network: "Bome Testnet",
        nativeCurrency: {
            decimals: 18,
            name: "BOME",
            symbol: "BOME",
        },
        rpcUrl: "https://testrpc.bomechain.org",
        explorerUrl: "https://testnet.bomescan.org",
    },
};
