import { Contract } from "web3-eth-contract";
// ens购买 pool
import ens_registrar_abi from "../../abi/ens-registrar-abi.json";

// erc20代币 - 暂时不用，先注释1.0只用原生代币。(作为例子放置)
// import supe_erc20_abi from "../abi/SUPEERC20.json";

const status = process.env.VUE_APP_ENV;

export let contractAddress = {};
if (status === "development") {
    contractAddress = {
        ens_registrar: {
            pool: "0x9Cd9307705040C8D7c48fDdA340D63378171c8c2",
        },
        // erc20代币 - (作为例子放置)
        // supe: {
        //     erc20: erc20 token 地址",
        // },
    };
} else {
    contractAddress = {
        ens_registrar: {
            pool: "0xF18dEc07ad7fD7c006c00B98EAE1033Fe89f319d",
        },
        // erc20代币 - (作为例子放置)
        // supe: {
        //     erc20: erc20 token 地址",
        // },
    };
}

function web3Provider(type, abi, address) {
    const rpcProvider = process.env.VUE_APP_BOME_RPC_URL;
    const contract = new Contract(abi, address);
    if (type) {
        contract.setProvider(rpcProvider);
    }
    return contract;
}

export const initContracts = () => {
    return {
        contractAddress,
        ens_registrar: {
            pool: web3Provider(1, ens_registrar_abi.abi, contractAddress.ens_registrar.pool),
        },
        // erc20代币 - (作为例子放置)
        // supe: {
        //     erc20: new Contract(supe_erc20_abi.abi, address.supe.erc20),
        // },
    };
};

export const initContractsSend = () => {
    return {
        contractAddress,
        ens_registrar: {
            pool: web3Provider(0, ens_registrar_abi.abi, contractAddress.ens_registrar.pool),
        },
        // erc20代币 - (作为例子放置)
        // supe: {
        //     erc20: new Contract(supe_erc20_abi.abi, address.supe.erc20),
        // },
    };
};
