const Home = () => import("@/views/Home");
const Account = () => import("@/views/Account");

const routes = [
    {
        path: "/",
        redirect: {
            name: "home",
        },
    },
    {
        path: "/home",
        name: "home",
        component: Home,
    },
    {
        path: "/account",
        name: "Account",
        component: Account,
    },
    {
        path: "/lmq",
        name: "lmq",
        component: () => import("@/views/LmqExample"),
    },
];
export default routes;
