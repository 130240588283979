import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
const debug = process.env.NODE_ENV !== "production";

const state = {
    lang: "en-US",
    loveLoading: false,
};

const mutations = {
    updateLang(state, value) {
        state.lang = value;
    },
    updateLoveLoading(state, value) {
        state.loveLoading = value;
    },
};
const actions = {};

export default createStore({
    state,
    mutations,
    actions,
    strict: debug,
    plugins: [createPersistedState()],
});
